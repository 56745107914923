<template>
  <v-dialog
    v-model="show"
    mix-height="90vh"
    max-width="40vw"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @keydown.esc="close">
    <v-card pt-3>
      <v-card-title class="form-header headline pa-2 font-weight-medium">
          {{ componentsText.action }} conta corretora
      </v-card-title>
      <v-card-text>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="brokerageAccount.provider_id"
                label="Corretora*"
                :items="brokerageFirms"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
                clearable
                required/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="brokerageAccount.name"
                label="Nome*"
                :rules="[rules.required, localRules.uniqueName]"
                required/>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="brokerageAccount.nickname"
                label="Nome abreviado*"
                :rules="[rules.required, localRules.counter, localRules.uniqueNickname]"
                counter="50"
                required/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="brokerageAccount.clearing"
                label="Clearing"
                hint="Corretora nos EUA"
                persistent-hint/>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="brokerageAccount.account"
                label="Conta"
                hint="Conta da corretora nos EUA"
                persistent-hint/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" no-gutters>
          <v-col class="text-right firefox-align-end">
            <v-btn
              color="error"
              @click="close">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              @click="save"
            >
              {{ componentsText.action }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixins from "@/mixins/form";

export default {
  name: "BrokerageAccountForm",
  props: {
    brokerageFirms: Array,
    userBrokerageAccounts: Array,
    gColor: String,
  },
  mixins: [FormMixins],
  data() {
    return {
      brokerageAccount: undefined,
      localRules: {
        counter: value => value && value.length <= 50 || 'máximo de 100 letras',
        uniqueName: value => !this.checkUniqueName(value) || "Este nome já foi utilizado",
        uniqueNickname: value => !this.checkUniqueNickname(value) || "Este nome já foi utilizado"
      },
      // Defaults values
      defaultBrokerageAccount : {
        name: undefined,
        nickname: undefined,
        clearing: undefined,
        account: undefined,
        provider_id: false,
        user_id: undefined,
      }
    }
  },
  methods: {
    save() {
      if (this.validate()) {
        this.brokerageAccount.is_deleted = false;
        this.brokerageAccount.user_id = this.user.id;
        this.setResource("brokerage_account");
        this.saveResource(this.brokerageAccount).then(response => {
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceList", response);
          this.close();
        }).catch(() => {
          this.$alertError(this.componentsText.mError)
        });
      }
    },
    initialize() {
      this.brokerageAccount = Object.assign({}, this.defaultBrokerageAccount);
    },
    toUpdate(item) {
      this.show = true;
      this.$nextTick(() => {
        this.brokerageAccount = Object.assign({}, item);
      });
    },
    checkUniqueName(name) {
      if(name && typeof name === 'string') {
        return this.accountsFiltered.findIndex(ba => ba.name.toLowerCase() === name.toLowerCase()) > -1
      }
      return false;
    },
    checkUniqueNickname(nickname) {
      if(nickname && typeof nickname === 'string') {
        return this.accountsFiltered.findIndex(ba => ba.nickname.toLowerCase() === nickname.toLowerCase()) > -1
      }
      return false;
    }
  },
  computed: {
    componentsText() {
      if (this.brokerageAccount && this.brokerageAccount.id) {
        return {
          action: "Atualizar",
          mSuccess: "Conta atualizada com sucesso!",
          mError: "Houve um problema ao atualizar a conta",
        };
      } else {
        return {
          action: "Adicionar",
          mSuccess: "Conta inserida com sucesso!",
          mError: "Houve um problema ao inserir a conta",
        };
      }
    },
    accountsFiltered() {
      // userBrokerageAccounts retorna todas os contas do user
      // mas para fazer update de uma conta é necessário excluir do array
      // para não invalidar o nome atual
      return this.userBrokerageAccounts.filter(ba => ba.id !== this.brokerageAccount.id);
    }
  },
  created() {
    this.initialize();
  },
}
</script>

<style>

</style>