<template>
  <v-card max-height="410" class="mx-auto">
    <base-material-card
      color="primary"
      width="2600"
      style="background-color: white"
      flat
      icon="mdi-account-cash"
      title="Conta Corretora"
    >
      <v-card color flat class="px-4 mt-4">
        <v-card-title color="white" class="pt-0">
          <v-row justify="space-between" align="start">
            <v-col
              id="list-crud"
              cols="10"
              class="text-md-end pb-sm-0 text-sm-left py-sm-7 py-md-2"
            >
              <div class="filters-wrapper">
                <div class="in-line mr-1 hidden-md-and-down">
                  <span>Filtros:</span>
                </div>
                <div class="in-line mx-2">
                  <v-text-field v-model="nameFilterer" label="Nome" clearable />
                </div>
                <div class="in-line mx-2 hidden-sm-and-down">
                  <v-text-field
                    v-model="nicknameFilterer"
                    label="Nome abreviado"
                    clearable
                  />
                </div>
                <div class="in-line mx-2">
                  <v-autocomplete
                    v-model="brokerageFilterer"
                    :items="brokerageFirms"
                    label="Corretora"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: 250 }"
                    clearable
                    auto-select-first
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="2" class="text-sm-right mb-sm-4">
              <v-btn
                large
                class="py-sm-0 py-2 font-weight-medium"
                dark
                color="primary"
                rounded
                :disabled="disableAdd"
                @click="openForm"
              >
                <!-- Larges screens -->
                <span class="hidden-md-and-down">Adicionar novo</span>
                <!-- Smalls screens -->
                <span class="hidden-lg-and-up">Novo</span>
              </v-btn>
              <v-alert
                v-if="disableAdd"
                border="top"
                colored-border
                type="warning"
                elevation="2"
                class="pb-0"
              >
                Adicione uma propriedade antes.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-resize="onResize">
            <v-skeleton-loader
              v-if="load"
              v-bind="crudSkelletonAttrs"
              type="table-heading@2, list-item-two-line"
              width="100%"
            />
            <agr-data-table
              v-else
              :headers="headerFiltered"
              :items="brokerageFiltered"
              :loading="load"
              :btn-actions="true"
              @edit="edit"
              @confirmDelete="confirmDelete"
              @duplicate="duplicate"
            >
              <!-- Itens slots -->
              <template v-slot:name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>
              </template>
            </agr-data-table>
          </v-row>
        </v-card-text>
      </v-card>
      <brokerage-account-form
        ref="form"
        v-model="dialog"
        :brokerageFirms="brokerageFirms"
        :userBrokerageAccounts="userBrokerageAccounts"
        :gColor="'#066f6a'"
        @updateResourceList="updateResourceList(results.objects, $event)"
      />
    </base-material-card>
  </v-card>
</template>

<script>
import BrokerageAccountForm from "./BrokerageAccountForm";
import CrudMixins from "@/mixins/crud";

export default {
  name: "BrokerageAccount",
  mixins: [CrudMixins],
  components: {
    BrokerageAccountForm,
  },
  data() {
    return {
      dialog: false,
      disableAdd: false,
      load: false,
      brokerageFirms: undefined,
      userBrokerageAccounts: undefined,
      headers: [
        {
          text: "Nome",
          tooltip: "Nome do fornecedor/cliente",
          align: "left",
          value: "name",
        },
        {
          text: "Nome abreviado",
          tooltip: "Apelido da conta",
          align: "center",
          value: "nickname",
        },
        {
          text: "Corretora",
          tooltip: "Corretora a que pertence a conta",
          align: "center",
          value: "provider.name",
        },
        {
          text: "Clearing",
          tooltip: "Corretora nos EUA",
          align: "center",
          value: "clearing",
        },
        {
          text: "Conta",
          tooltip: "Conta da corretora nos EUA",
          align: "center",
          value: "account",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o fornecedor/cliente",
          value: "actions",
          sortable: false,
        },
      ],
      nameFilterer: undefined,
      nicknameFilterer: undefined,
      brokerageFilterer: undefined,
    };
  },
  computed: {
    brokerageFiltered() {
      // criteria definida no crud mixin
      if (!this.criteria.length) return this.results.objects;
      // propotype definido no crud mixin
      return this.results.objects.dynamicFilter(this.criteria);
    },
    headerFiltered() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          this.headers[0],
          this.headers[2],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },
  },
  methods: {
    searchBrokerage() {
      let payload = {
        resource: "provider",
        params: {
          my: true,
          checkDelete: true,
          brokerageFirmsOnly: true,
        },
      };
      this.load = true;
      // Busca primeiros os clientes
      this.getResource(payload).then((result) => {
        this.brokerageFirms = result;
        // Depois os silos
        payload.resource = "brokerage_account";
        this.search(payload).then(() => {
          this.load = false;
          this.userBrokerageAccounts = this.results.objects.map((r) => {
            return {
              id: r.id,
              name: r.name,
              nickname: r.nickname,
            };
          });
        });
      });
    },
    edit(ubrokerage) {
      this.$refs.form.toUpdate(ubrokerage);
    },
    confirmDelete(dbrokerage) {
      this.setResource("brokerage_account");
      dbrokerage.is_deleted = true;
      dbrokerage.deleted_date = new Date().toISOString();
      this.update(dbrokerage)
        .then(() => {
          this.$alertSuccess("Conta deleteda com sucesso!");
          this.removeFromResourceList(this.results.objects, dbrokerage);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar a conta!");
        });
    },
  },
  created() {
    this.searchBrokerage();
  },
  watch: {
    nameFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "name");
    },
    nicknameFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "nickname");
    },
    brokerageFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, "provider_id");
    },
  },
};
</script>

<style>
</style>